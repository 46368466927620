<template>
  <div>
    <div class="text-area">
      {{ filteredText }}
    </div>
    <button class="showAllButton" @click="showModal(value.id)" :class="[showButton ? 'd-block' : 'd-none']"> Devamını gör</button>
    <div v-if="showButton">
        <b-modal :id="`modal-aciklama-${value.id}`" :title="field">
            <p class="my-4">{{value[field]}}</p>
            <template #modal-footer>
                <button class="btn btn-success" @click="$bvModal.hide(`modal-aciklama-${value.id}`);">Kapat</button>
            </template>
        </b-modal>
</div>
  </div>
</template>
<script>
export default {
  name: "Moretext",
  props: { value: Object,field:String },
  data(){
    return{
        text:"",
        showButton:false,
        filteredText:""
    }
  },
  mounted(){
    this.text = this.value[this.field]
    this.showButtonTrigger()
  },
  methods:{
    showButtonTrigger(){
        const length = this.text.length
        const maxLength = 30
        if(length > maxLength){
            this.showButton = true
            this.filteredText = this.text.substring(0,maxLength) + "..."
        }else{
            this.filteredText = this.text
        }
    },
    showModal(item) {
      this.$bvModal.show(`modal-aciklama-${item}`);
    },
  }
  
};
</script>
<style lang="scss" scoped>
.showAllButton{
    color:$dveb-info
}
</style>

